import React, { useEffect, useMemo, useState } from 'react';
import {
  DEFAULT_PREV_SEARCH_VALUE,
  DISPLAYED_SEARCH_RESULT_FEATURES,
  PROPERTIES_PER_PAGE,
  PROPERTY_DETAILS_TAB,
} from '../../constans/search';
import { formatCurrency, pvPipe } from '../../helpers/helper-methods';

import styles from './PvCard.module.scss';
import { PREFIX_PROPERTY_BOOK_BUTTON, PROPERTY_BOOK_BUTTON } from '../../constans/texts/texts';
import http from '../../services/http.service';
import { API } from '../../constans/http';
import StorageService from '../../services/storage.service';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';

function PvCard({
  title,
  PV,
  totalPrice,
  room,
  features,
  isActive,
  id,
  onButtonClick,
  currencyCode,
  index,
  page,
  numberOfRooms,
}) {
  const [propertyData, setPropertyData] = useState(null);

  useEffect(() => {
    if (id) {
      http
        .get(`${API.PROPERTIES}/${id}`)
        .then(({ data }) => {
          setPropertyData(data);
        }).catch();
    }
  }, [id]);

  const storage = StorageService();

  const { values } = useMemo(() => storage
    .get(LOCALSTORAGE_KEYS.SEARCH, DEFAULT_PREV_SEARCH_VALUE), [storage]);

  const {
    adultGuests, checkin, checkout, kidGuests, kids, rooms,
  } = values;

  let link = propertyData?.affiliateLink || '';
  link = link.replace(/&no_rooms=[^&]*/, '');
  link = link.replace(/&group_adults=[^&]*/, '');
  link = link.replace(/&&/g, '&');
  link = link.trim();

  link = `${link}
    &checkin=${encodeURIComponent(checkin)}
    &checkout=${encodeURIComponent(checkout)}
    &group_adults=${encodeURIComponent(adultGuests)}
    &no_rooms=${encodeURIComponent(rooms)}  
    &group_children=${encodeURIComponent(kidGuests)}`;

  if (kids.length > 0) {
    for (let i = 0; i < kidGuests; i++) {
      link += `&age=${encodeURIComponent(kids[i])}`;
    }
  }

  return (
    <div
      onClick={() => onButtonClick({ id, tabIndex: PROPERTY_DETAILS_TAB })}
      className={`${styles.pvCard} ${isActive && styles.active}`}
    >
      <div className="flex flex-between">
        <div className={styles.pvCardPoints}>
          {pvPipe(index, PV, PROPERTIES_PER_PAGE, page)}
        </div>
        {currencyCode && totalPrice
          ? (
            <div className={styles.pvCardPrice}>
              {formatCurrency(currencyCode, totalPrice * numberOfRooms)}
            </div>
          )
          : null}
      </div>

      <div className={`${styles.pvCardTitle} text-truncate`}>
        {title}
      </div>

      <p className={`${styles.pvCardRoom} text-truncate`}>
        <span>Room type:</span>
        {' '}
        {room?.title}
      </p>
      {features?.length > 0 ? (
        <div className={styles.pvCardFeatureWrapper}>
          {features.slice(0, DISPLAYED_SEARCH_RESULT_FEATURES)
            .map(({ title: featureTitle }, innerIndex) => (
              <div key={innerIndex} className={styles.pvCardFeature}>
                <div className={styles.dot} />
                {featureTitle}
              </div>
            ))}
          {features.length - DISPLAYED_SEARCH_RESULT_FEATURES > 0 ? (
            <div className={styles.pvCardFeatureRest}>
              {`+ ${features.length - DISPLAYED_SEARCH_RESULT_FEATURES} ${features.length - DISPLAYED_SEARCH_RESULT_FEATURES > 1 ? 'features' : 'feature'}`}
            </div>
          ) : null}
        </div>
      ) : null}

      { propertyData?.affiliateLink
      && (
      <div className={`flex ${styles.pvCardButtons} ${styles.link} ${isActive && styles.activeLink}`}>
        <a
          href={link}
          target="_blank"
          rel="noreferrer noopener"
          className="btn t-600"
          onClick={(event) => event.stopPropagation()}
        >
          {PREFIX_PROPERTY_BOOK_BUTTON}
          {PROPERTY_BOOK_BUTTON}
        </a>
      </div>
      )}
    </div>
  );
}

export default PvCard;
