import React from 'react';

import { RESULT_CRUMBS_REQUIRED_FEATURES } from '../../constans/search';
import { getQueryParams } from '../../helpers/helper-methods';
import SearchResults from '../../views/search-results/SearchResults';

const Results = ({ location }) => {
  const { search } = location;
  const {
    requestId,
    adultGuests,
    kidGuests,
    checkin,
    checkout,
    requiredOptions,
    searchLocation,
    kids,
    currency,
    numberOfRooms,
    ...filters
  } = getQueryParams(search);

  const displayRequiredOptions = (options = []) => options
    .map((option) => RESULT_CRUMBS_REQUIRED_FEATURES[option]);

  Object
    .keys(filters)
    .forEach((key) => { filters[key] = true; });

  return (
    <SearchResults
      requestId={requestId}
      searchFilters={filters}
      searchLocation={searchLocation}
      kids={kids}
      currency={currency}
      checkin={checkin}
      checkout={checkout}
      location={location}
      kidGuests={kidGuests}
      adultGuests={adultGuests}
      numberOfRooms={numberOfRooms}
      requiredOptions={displayRequiredOptions(requiredOptions)}
    />
  );
};

export default Results;
