import React, {
  useEffect, useState, useMemo,
} from 'react';
import { navigate } from 'gatsby';
import moment from 'moment';

import SearchService from '../../services/search.service';
import Layout from '../../components/Layout/Layout';
import Pagination from '../../components/Pagination';
import PvCard from '../../components/PvCard/PvCard';
import Spinner from '../../components/UI/Spinner/Spinner';
import SearchFilters from '../../components/SearchFilters/SearchFilters';
import { Consumer, Provider } from '../../components/Tabs/Context';
import SearchResultPropertyInfo from '../../components/SearchResultPropertyInfo/SearchResultPropertyInfo';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import Arrow from '../../assets/img/icons/ic-chevron-right-light-gray.svg';
import { TABLET_LANDSCAPE_WIDTH_BREAKPOINT } from '../../constans/header';
import {
  SEARCH_RESULTS_FILTERS,
  SEARCH_MOBILE_TABS,
  PROPERTIES_PER_PAGE,
  INITIAL_SEARCH_RESULT_FILTER_VALUES,
  ADULT_PREFIX,
  KIDS_PREFIX,
  MODAL_TIMEOUT_TIME,
  ALGO_TIMEOUT,
} from '../../constans/search';
import { RESULTS_FORMAT } from '../../constans/formats';

import styles from './SearchResults.module.scss';
import StorageService from '../../services/storage.service';
import { STORAGE_DESTINATION } from '../../components/DestinationTree';
import { getRandomString } from '../../helpers/getRandomMessage';
import { isAuthGuard } from '../../guards/isAuthGuard';
import SignUpModal from '../../components/AutorizationModal/SignUpModal';

const displayGuests = (adultsAmount, kidsAmount = '') => `
    ${adultsAmount ? `${adultsAmount}${ADULT_PREFIX}` : ''} 
    ${kidsAmount ? `${adultsAmount ? '+' : ''} ${kidsAmount}${KIDS_PREFIX}` : ''}
  `;

export const displayDates = (start, end) => (
  `${moment(start).format(RESULTS_FORMAT)} - ${moment(end).format(RESULTS_FORMAT)}`
);

function SearchResults({
  requestId,
  searchFilters,
  searchLocation,
  currency,
  checkin,
  checkout,
  kidGuests,
  adultGuests,
  numberOfRooms,
  kids,
}) {
  const searchService = useMemo(() => SearchService(), []);
  const [properties, setProperties] = useState([]);
  const [activeTab, setActiveTab] = useState(SEARCH_MOBILE_TABS.PV_CARDS);
  const [activeProperty, setActiveProperty] = useState(properties?.[0]);
  const [totalProperties, setTotalProperties] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState(searchFilters);
  const width = useWindowWidth();
  const isTabletLandscape = width >= TABLET_LANDSCAPE_WIDTH_BREAKPOINT;
  const [supposedPropertiesAmount, setSupposedPropertiesAmount] = useState(null);
  const [propertiesFiltersLoading, setPropertiesFiltersLoading] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);

  const isAuth = isAuthGuard();

  displayDates(checkin, checkout);
  displayGuests(adultGuests, kidGuests);

  const storageDestination = StorageService().get(STORAGE_DESTINATION);
  const getProperties = async ({
    selected: page,
    uuid = requestId,
    limit = PROPERTIES_PER_PAGE,
    filterOptions = {},
  }) => {
    const model = {
      uuid,
      countryId: storageDestination?.country?.id,
      regionId: storageDestination?.region?.id,
      cityId: storageDestination?.city?.id,
      districtId: storageDestination?.district?.id,
      limit,
      offset: page * PROPERTIES_PER_PAGE,
      ...filterOptions,
    };

    const { data, pagination } = await searchService.getPropertiesByUUID(model);
    const { totalCount } = pagination;

    setCurrentPage(page);
    setProperties(data);
    setTotalProperties(totalCount);
    setActiveProperty(data?.[0]?.id);

    if (pagination === 0) {
      navigate('/nothing-found');
    }
  };

  const searchFilterChangeHandler = (filterParams) => {
    setPropertiesFiltersLoading(true);

    const model = {
      uuid: requestId,
      countryId: storageDestination?.country?.id,
      regionId: storageDestination?.region?.id,
      cityId: storageDestination?.city?.id,
      districtId: storageDestination?.district?.id,
      limit: 0,
      offset: 0,
      //    ...filterParams,
    };
    searchService.getPropertiesByUUID(model)
      .then(({ pagination }) => {
        const { totalCount } = pagination;
        setSupposedPropertiesAmount(totalCount);
        setPropertiesFiltersLoading(false);
      });
  };

  useEffect(() => {
    const obj = { selected: 0, filterOptions: filters, limit: PROPERTIES_PER_PAGE };
    setTimeout(getProperties, ALGO_TIMEOUT, obj);
    // getProperties({ selected: 0, filterOptions: filters, limit: PROPERTIES_PER_PAGE });
  }, [requestId, filters]);

  useEffect(() => {
    setTimeout(() => setIsModalShown(true), MODAL_TIMEOUT_TIME + ALGO_TIMEOUT);
  }, []);

  return (
    <Layout mainClasses="column-flexed-wrapper">
      <div className="flex flex-grow-1 flex-h-start">
        <div className={`container ${styles.page}`}>
          <Provider>
            <div className={`row ${styles.content}`}>
              {(activeTab === SEARCH_MOBILE_TABS.PV_CARDS || isTabletLandscape)
                  && (
                    properties.length > 0 ? (
                      <div className="col-12 col-lg-4">
                        <div className="flex flex-between">
                          {!!totalProperties && <div className={styles.pvTitle}>{`${totalProperties} Properties in ${searchLocation}`}</div>}
                          <Consumer>
                            {({ activeIndex }) => (
                              <div>
                                <SearchFilters
                                  propertiesFilter
                                  containerClassName="col-9 col-md-5 col-lg-8"
                                  initialValues={INITIAL_SEARCH_RESULT_FILTER_VALUES}
                                  selectedValues={searchFilters}
                                  filterOptions={SEARCH_RESULTS_FILTERS}
                                  valueField="value"
                                  onSubmit={setFilters}
                                  searchFilterChangeHandler={searchFilterChangeHandler}
                                  supposedPropertiesAmount={supposedPropertiesAmount}
                                  propertiesFiltersLoading={propertiesFiltersLoading}
                                />
                              </div>
                            )}
                          </Consumer>
                        </div>

                        <div className="flex flex-between flex-wrap">
                          <Consumer>
                            {({ setActiveIndex }) => {
                              const handleCardClick = (id) => {
                                setActiveTab(SEARCH_MOBILE_TABS.PROPERTY);
                                setActiveProperty(id);
                              };

                              const onButtonClick = ({ id, tabIndex }) => {
                                handleCardClick(id);
                                setActiveIndex(tabIndex);
                              };
                              return properties.map(({ offer, ...card }, index) => (
                                <PvCard
                                  {...card}
                                  features={offer?.features}
                                  key={card.id}
                                  onButtonClick={onButtonClick}
                                  isActive={card.id === activeProperty}
                                  index={index}
                                  page={currentPage}
                                  numberOfRooms={numberOfRooms}
                                />
                              ));
                            }}
                          </Consumer>
                        </div>

                        <div className="flex flex-center">
                          <Pagination
                            total={totalProperties}
                            perPage={PROPERTIES_PER_PAGE}
                            callback={getProperties}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className={styles.wrapper}>
                        <Spinner spinnerClasses="spinner-primary" />
                        <div className={styles.fadeInText}>
                          <h1 className={styles.randomText}>{getRandomString()}</h1>
                        </div>
                      </div>
                    )
                  )}

              {(activeTab === SEARCH_MOBILE_TABS.PROPERTY || isTabletLandscape)
                && (
                  properties.length > 0 && (
                    <Consumer>
                      {({ activeIndex, setActiveIndex }) => (
                        <SearchResultPropertyInfo
                          GoBackBtn={(
                            <button
                              onClick={() => setActiveTab(SEARCH_MOBILE_TABS.PV_CARDS)}
                              className={`btn ${styles.buttonBack}`}
                              type="button"
                            >
                              <Arrow className={styles.buttonBackArrow} />
                              Go Back
                            </button>
                          )}
                          containerStyles="col-12 col-lg-8"
                          requestId={requestId}
                          activePropertyId={activeProperty}
                          filters={filters}
                          activeIndex={activeIndex}
                          setActiveIndex={setActiveIndex}
                          currency={currency}
                          checkin={checkin}
                          checkout={checkout}
                          adultGuests={adultGuests}
                          kidGuests={kidGuests}
                          numberOfRooms={numberOfRooms}
                          kids={kids}
                        />
                      )}
                    </Consumer>
                  )
                )}
            </div>
          </Provider>
        </div>
      </div>
      <SignUpModal
        isShown={!isAuth && isModalShown}
        toggleModal={() => setIsModalShown()}
        isSignIn={false}
      />
    </Layout>
  );
}

SearchResults.defaultProps = {
  requestId: 'abede041-3983-407e-b5ee-a7367f099f23',
};

export default SearchResults;
